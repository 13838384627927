//@tailwind base;
//@tailwind components;
//@tailwind utilities;

@layer utilities {
  .grid-container {
    @apply grid gap-x-4 md:grid-cols-12 sm:grid-cols-8 grid-cols-4;
  }
}

@mixin typoStyle($tmpWeight, $tmpSize, $tmpLH) {
  $fontStyle: normal;

  font-style: $fontStyle;
  font-weight: $tmpWeight;
  font-size: $tmpSize;
  line-height: $tmpLH;
}

//html,body {
//    height: 100%;
//    margin:0;
//    font-size: 13px;
//}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  //  typography className
  .body1 {
    @include typoStyle(400, 1rem, 1.1875rem);
  }

  .body2 {
    @include typoStyle(400, 0.875rem, 1rem);
  }

  .subtitle1 {
    @include typoStyle(500, 1rem, 1.1875rem);
  }

  .subtitle2 {
    @include typoStyle(500, 0.875rem, 1rem);
  }

  .caption {
    @include typoStyle(400, 0.75rem, 0.875rem);
  }

  .h1 {
    @include typoStyle(500, 6rem, 7rem);
  }

  .h2 {
    @include typoStyle(500, 3.75rem, 4.375rem);
  }

  .h3 {
    @include typoStyle(500, 3rem, 3.5rem);
  }

  .h4 {
    @include typoStyle(500, 2.125rem, 2.5rem);
  }

  .h5 {
    @include typoStyle(500, 1.5rem, 1.75rem);
  }

  .h6 {
    @include typoStyle(500, 1.25rem, 1.4375rem);
  }
}

.wrapper {
  flex-grow: 1;
}

